<template>
  <PVSidebar v-if="visible" v-model:visible="visible" class="!w-[50%]">
    <template #header>
      <div class="flex items-center whitespace-nowrap truncate">
        <span>
          {{
            `${selectedGroup.id ? t('users.edit_group') : t('users.new_group_addition')}`
          }}
        </span>
        <div v-if="isSchahafGroup" class="flex items-center gap-2">
          <span>/</span>
          <span class="text-[#F0920F] font-normal">
            {{ t('users.edit_group_alert') }}
          </span>
        </div>
      </div>
    </template>
    <loading-overlay
      v-model:active="fethTeachers"
      :can-cancel="false"
      :is-full-page="false"
      :height="30"/>
    <div class="flex flex-col h-full">
      <div class="flex flex-col flex-1 overflow-y-auto px-5 py-4">
        <div class="flex flex-col gap-2">
          <span class="font-simplerRegular text-sm text-secondary-900">
            שם הקבוצה
          </span>
          <PVInputText
            v-model="selectedGroup.name"
            placeholder="כאן מכניסים את שם הקבוצה"
            class="pl-7 !w-[21em]"/>
        </div>
        <div class="flex flex-col gap-2">
          <span class="font-simplerRegular text-sm mt-5 text-secondary-900">
            הוספת מורים
          </span>
          <PVAutoComplete
            v-model="selectedTeachers"
            class="!w-[18.3em]"
            multiple
            option-label="fullName"
            placeholder="בחירת מורה"
            empty-search-message="לא נמצא כזה מורה"
            :suggestions="filteredTeachers"
            @update:model-value="toggleSelectionTeacher"
            @complete="searchTeachers"/>
        </div>
        <div class="flex flex-col flex-1 gap-3">
          <span class="font-simplerRegular text-sm mt-5 text-secondary-900">
            הוספת תלמידים
          </span>
          <PVTabMenu v-model:active-index="activeTab" :model="tabs"></PVTabMenu>
          <div
            v-if="activeTab === TABS_INDEX.students_list"
            class="flex flex-col flex-1 h-full gap-4 bg-secondary-75 p-4 rounded-md overflow-y-auto">
            <div class="flex gap-2">
              <PVDropdown
                v-model="selectedClass"
                class="w-48"
                :options="groupedClasses"
                option-label="label"
                option-group-label="label"
                option-group-children="items"
                option-value="value"
                placeholder="כל הכיתות"/>
              <SearchInput
                v-model="search"
                placeholder="חיפוש לפי שם"
                :style="'neutral'"
                @reset-search="search = ''"/>
            </div>
            <div class="flex items-center gap-1">
              <PVCheckbox
                v-model="selectAllStudentsCheckbox"
                :binary="true"
                @update:model-value="value => toggleAllStudentsSelection(value)"/>
              <span class="text-secondary-975 text-xs">סימון כל התלמידים</span>
            </div>
            <div class="flex flex-1 w-full overflow-y-hidden">
              <PVVirtualScroller
                v-if="studentsList.length"
                :items="studentsListForVirtualScroller"
                :item-size="[50, 2]"
                orientation="both"
                class="flex flex-1 min-h-[150px]">
                <template #item="{ item: pair }">
                  <div
                    :class="[
                      'w-full align-items-center grid grid-cols-2 [&:not(:first-child)]:border-t border-secondary-250',
                    ]"
                    style="height: 50px">
                    <template v-for="user of pair" :key="user.id">
                      <div
                        class="py-3 px-5 cursor-pointer bg-white hover:bg-secondary-50 active:bg-primary-100 [&:not(:first-child)]:border-r border-secondary-250"
                        @click="toggleSelectionStudent(user)">
                        <div
                          class="flex items-center justify-between cursor-pointer gap-2 w-full">
                          <div class="flex gap-2 items-center">
                            <UserIcon />
                            <span class="text-sm">{{ user.fullName }}</span>
                            <span class="text-xs text-secondary-800">
                              {{ user.class }}
                            </span>
                          </div>
                          <PVCheckbox
                            v-model="selectedGroup.students"
                            :input-id="user.id"
                            name="user"
                            :value="user.id"
                            @click="e => e.preventDefault()"/>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </PVVirtualScroller>
              <div v-else>
                <span class="text-secondary-800 text-sm">לא נמצאו תלמידים</span>
              </div>
            </div>
          </div>
          <div
            v-if="activeTab === TABS_INDEX.selected_students"
            class="flex flex-col flex-1 gap-4 bg-secondary-75 p-4 rounded-md overflow-y-auto">
            <div class="overflow-auto mb-2 pl-1">
              <div v-if="selectedStudentsList.length" class="grid grid-cols-2">
                <div
                  v-for="user in selectedStudentsList"
                  :key="user.id"
                  class="py-3 px-5 cursor-pointer bg-white [&:not(:nth-child(2n))]:border-l border-secondary-250 [&:not(:nth-child(-n+2))]:border-t">
                  <div
                    class="flex justify-between items-center cursor-pointer gap-2 w-full">
                    <div class="flex gap-2 items-center">
                      <UserIcon />
                      <span class="text-sm">{{ user.fullName }}</span>
                      <span class="text-xs">{{ user.class }}</span>
                    </div>
                    <span
                      class="bg-[#F4F7FB] hover:bg-secondary-200 active:bg-primary-200 w-5 h-5 flex items-center justify-center rounded-4"
                      @click="toggleSelectionStudent(user)">
                      <Close
                        :size="15"
                        stroke-width="1px"
                        class="text-secondary-900"/>
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="text-secondary-800 text-sm flex justify-center items-center flex-1">
                אין תלמידים בקבוצה
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex justify-end items-center gap-2 px-5 py-4 border-t border-secondary-250 shadow-sm">
        <PVButton
          label="ביטול"
          class="!w-16 !min-w-16"
          outlined
          @click="visible = false"/>
        <PVButton
          :label="
            $t(selectedGroup.id ? 'users.edit_group' : 'users.create_group')
          "
          :loading="loading"
          :disabled="!validateForm()"
          @click="onDrawerAction"/>
      </div>
    </div>
  </PVSidebar>
</template>

<script setup lang="ts">
import { SearchInput } from '@amit/foundation';
import { computed, ref, watch } from 'vue';
import { useSchoolStore } from '/@/views/playground/school-store';
import { useI18n } from 'vue-i18n';
import {
  createGroup,
  editGroupAddMembers,
  editGroupName,
  editGroupRemoveMembers,
} from '../school-service';
import UserIcon from '/@/assets/userIcon.svg?component';
import { useToast } from 'primevue/usetoast';
import PVInputText from 'primevue/inputtext';
import PVSidebar from 'primevue/sidebar';
import PVDropdown from 'primevue/dropdown';
import PVButton from 'primevue/button';
import PVAutoComplete from 'primevue/autocomplete';
import PVCheckbox from 'primevue/checkbox';
import PVVirtualScroller from 'primevue/virtualscroller';
import PVTabMenu from 'primevue/tabmenu';
import { useRoute } from 'vue-router';
import { difference, groupBy, isEmpty, orderBy, uniqBy } from 'lodash';
import swal from 'sweetalert';
import { X as Close } from 'lucide-vue-next';
import { useAccountStore } from '/@/app/store/account';

const route = useRoute();

const toast = useToast();

const visible = ref(false);
const isSchahafGroup = ref(false);

const { t } = useI18n();

const schoolStore = useSchoolStore();
const accountStore = useAccountStore();

const selectedGroup = ref({});

const search = ref('');
const loading = ref(false);
const fethTeachers = ref(false);

const selectedTeachers = ref();
const filteredTeachers = ref();

const mentorshipSchool = computed(() => schoolStore.data?.mentorship);

const selectedTeachersSet = computed(
  () => new Set(selectedGroup.value.teachers ?? []),
);

const teachers = computed(() => schoolStore.teachers);

const searchTeachers = event => {
  if (!event.query.trim().length) {
    filteredTeachers.value = [...teachers.value];
  } else {
    filteredTeachers.value = teachers.value.filter(
      teacher =>
        teacher.fullName.toLowerCase().startsWith(event.query.toLowerCase()) &&
        !selectedTeachersSet.value.has(teacher.id),
    );
  }
};

const selectedClass = ref('');
const groupedClasses = computed(() => {
  const availableClasses = orderBy(
    uniqBy(
      schoolStore.students
        .filter(student => student.class)
        .map(student => ({
          class: student.class,
          agg: student.class.split('/')[0],
        })),
      'class',
    ),
    'agg',
  );
  const groups = groupBy(availableClasses, 'agg');
  const result = Object.keys(groups ?? {}).map(level => ({
    label: `שכבה ${level}`,
    code: level,
    items: groups[level]
      .map(info => ({
        label: info.class,
        value: info.class,
      }))
      .sort(
        (a, b) =>
          parseInt(a.value.split('/')[1], 10) -
          parseInt(b.value.split('/')[1], 10),
      ),
  }));
  return [
    {
      code: 'כל הכיתות',
      items: [
        {
          label: 'כל הכיתות',
          value: '',
        },
      ],
    },
    ...result,
  ];
});

const selectedStudentsSet = computed(
  () => new Set(selectedGroup.value.students ?? []),
);

const studentsList = computed(() =>
  schoolStore.students.filter(
    student =>
      student.fullName.includes(search.value) &&
      (selectedClass.value ? student.class === selectedClass.value : true),
  ),
);
const selectedStudentsList = computed(() =>
  schoolStore.students.filter(
    student =>
      student.fullName.includes(search.value) &&
      selectedStudentsSet.value.has(student.id) &&
      (selectedClass.value ? student.class === selectedClass.value : true),
  ),
);

const studentsListForVirtualScroller = computed(() => {
  let result = [];
  for (let i = 0; i < studentsList.value.length; i += 2) {
    if (i + 1 < studentsList.value.length) {
      result.push([studentsList.value[i], studentsList.value[i + 1]]);
    } else {
      result.push([studentsList.value[i]]); // Handles the last element in case of an odd-length array
    }
  }

  return result;
});

const tabs = computed(() => [
  { label: t('users.students_list') },
  {
    label: `${t('users.selected_students')} (${selectedGroup.value.students?.length ?? 0})`,
  },
]);

const TABS_INDEX = {
  students_list: 0,
  selected_students: 1,
};

const activeTab = ref(0);

watch(
  () => [activeTab.value, visible.value],
  () => {
    selectedClass.value = '';
    search.value = '';
    selectAllStudentsCheckbox.value = false;
  },
);

watch(
  () => [selectedClass.value, search.value],
  () => {
    if (selectAllStudentsCheckbox.value)
      selectAllStudentsCheckbox.value = false;
  },
);

const selectAllStudentsCheckbox = ref(false);

const toggleAllStudentsSelection = value => {
  const students = new Set(selectedGroup.value.students ?? []);
  studentsList.value.forEach(({ id }) => {
    if (value) {
      students.add(id);
    } else {
      students.delete(id);
    }
  });
  selectedGroup.value.students = Array.from(students);
};

const originalSelectedGroup = ref({});
const groupOwnerId = ref();

const open = ({
  groupId,
  readonlyMode = false,
  ownerId = null,
  schoolId = route.params.school,
}) => {
  const waitForData = () => {
    visible.value = true;
    fethTeachers.value = true;
    return new Promise<void>(resolve => {
      const interval = setInterval(() => {
        if (
          schoolStore.teachers.length > 0 &&
          (!groupId || schoolStore.groups.length > 0)
        ) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  };
  waitForData().then(() => {
    originalSelectedGroup.value.owner_id = null;
    if (!groupId) {
      selectedGroup.value = {
        name: '',
        students: [],
        teachers: ownerId ? [ownerId] : [],
        school_id: schoolId,
      };
      selectedTeachers.value = ownerId
        ? [schoolStore.teachers.find(({ id }) => id === ownerId)].filter(
            Boolean,
          )
        : [];
    } else {
      originalSelectedGroup.value = {
        ...(schoolStore.groups.find(group => group.id === groupId) ?? {}),
      };
      selectedGroup.value = {
        id: groupId,
        name: originalSelectedGroup.value.name,
        students: originalSelectedGroup.value.students.map(({ id }) => id),
        teachers: originalSelectedGroup.value.teachers.map(({ id }) => id),
        school_id: schoolId,
      };
      selectedTeachers.value = originalSelectedGroup.value.teachers;
    }
    visible.value = true;
    fethTeachers.value = false;
    isSchahafGroup.value = readonlyMode;
    groupOwnerId.value = ownerId ?? originalSelectedGroup.value.owner_id;
    activeTab.value = groupId ? 1 : 0;
  });
};

defineExpose({ open });

const toggleSelectionStudent = ({ id }) => {
  const users = new Set(selectedGroup.value.students);
  if (users.has(id)) {
    users.delete(id);
  } else {
    users.add(id);
  }
  selectedGroup.value.students = Array.from(users);
};

const toggleSelectionTeacher = value => {
  selectedTeachers.value = value;
  selectedGroup.value.teachers = value.map(({ id }) => id);
  // makes sure they don't remove the owner of this group
  if (
    !selectedTeachersSet.value.has(groupOwnerId.value) &&
    groupOwnerId.value
  ) {
    selectedGroup.value.teachers.push(groupOwnerId.value);
    selectedTeachers.value.push(
      teachers.value.find(({ id }) => id === groupOwnerId.value),
    );
  }
};

const validateForm = () => {
  const validations = {
    nameValid: !isEmpty(selectedGroup.value.name?.trim()),
    teachersValid: !isEmpty(selectedGroup.value.teachers),
    studentsValid:
      mentorshipSchool.value || !isEmpty(selectedGroup.value.students),
  };

  return Object.values(validations).every(Boolean);
};

const showMessage = (type, action, showText = true) => {
  const message = {
    title: t(`users.${type}.${action}.title`),
    text: showText ? t(`users.${type}.${action}.text`) : '',
    icon: type === 'success' ? 'success' : 'error',
  };
  swal(message);
};

const onDrawerAction = () => {
  const formIsValid = validateForm();

  if (!formIsValid) {
    showMessage('errors', 'invalid_form');
    return;
  }

  selectedGroup.value.name = selectedGroup.value.name.trim();

  if (selectedGroup.value.id) {
    editGroup();
  } else {
    loading.value = true;
    const { onSuccess, onError } = createGroup(selectedGroup.value);
    onSuccess(async () => {
      await fetchData();
      toast.add({
        severity: 'success',
        summary: `נוצרה קבוצת הלימוד ${selectedGroup.value?.name}`,
        life: 4000,
      });
    });
    onError(() => {
      loading.value = false;
      toast.add({
        severity: 'error',
        summary: 'יצירת קבוצת הלימוד נכשלה',
        life: 7000,
      });
    });
  }
};

const loadingEdit = ref({
  name: false,
  addMembers: false,
  removeMembers: false,
});

const fetchData = async () => {
  loading.value = true;
  if (route.params.school) {
    await schoolStore.fetch(route.params.school);
  } else {
    await accountStore.getUser(true);
  }
  visible.value = false;
  loading.value = false;
};

watch(
  () => [
    loadingEdit.value.name,
    loadingEdit.value.addMembers,
    loadingEdit.value.removeMembers,
  ],
  () => {
    loading.value = Object.values(loadingEdit.value).some(loading => loading);

    if (!loading.value) {
      fetchData();
    }
  },
);

const editGroup = async () => {
  if (isSchahafGroup.value) {
    selectedGroup.value.sis_id = null;
  }
  const callEdit =
    selectedGroup.value.name !== originalSelectedGroup.value.name ||
    (isSchahafGroup.value && !selectedGroup.value.sis_id);
  if (callEdit) {
    loadingEdit.value.name = true;
    const { onSuccess, onError } = editGroupName(
      selectedGroup.value.id,
      selectedGroup.value.name,
      selectedGroup.value.sis_id,
    );
    onSuccess(() => {
      loadingEdit.value.name = false;
      toast.add({
        severity: 'success',
        summary: `נערכה קבוצת הלימוד ${selectedGroup.value?.name}`,
        life: 4000,
      });
    });
    onError(() => {
      loadingEdit.value.name = false;
      toast.add({
        severity: 'error',
        summary: 'עריכת קבוצת הלימוד נכשלה',
        life: 4000,
      });
    });
  }

  const membersToAdd = {
    teachers: difference(
      selectedGroup.value.teachers,
      originalSelectedGroup.value.teachers.map(({ id }) => id),
    ),
    students: difference(
      selectedGroup.value.students,
      originalSelectedGroup.value.students.map(({ id }) => id),
    ),
  };

  if (membersToAdd.students.length || membersToAdd.teachers.length) {
    loadingEdit.value.addMembers = true;
    const { onSuccess, onError } = editGroupAddMembers(
      selectedGroup.value.id,
      membersToAdd,
    );
    onSuccess(() => {
      loadingEdit.value.addMembers = false;
      toast.add({
        severity: 'success',
        summary: 'התווספו בהצלחה לקבוצת הלימוד',
        life: 4000,
      });
    });
    onError(() => {
      loadingEdit.value.addMembers = false;
      toast.add({
        severity: 'error',
        summary: 'נכשלה ההוספה לקבוצת הלימוד',
        life: 4000,
      });
    });
  }

  const membersToRemove = {
    teachers: difference(
      originalSelectedGroup.value.teachers.map(({ id }) => id),
      selectedGroup.value.teachers,
    ),
    students: difference(
      originalSelectedGroup.value.students.map(({ id }) => id),
      selectedGroup.value.students,
    ),
  };

  if (membersToRemove.teachers.length || membersToRemove.students.length) {
    loadingEdit.value.removeMembers = true;
    const { onSuccess, onError } = editGroupRemoveMembers(
      selectedGroup.value.id,
      membersToRemove,
    );
    onSuccess(() => {
      loadingEdit.value.removeMembers = false;
      toast.add({
        severity: 'success',
        summary: 'הוסרו בהצלחה מקבוצת הלימוד',
        life: 4000,
      });
    });
    onError(() => {
      loadingEdit.value.removeMembers = false;
      toast.add({
        severity: 'error',
        summary: 'נכשלה ההסרה מקבוצת הלימוד',
        life: 4000,
      });
    });
  }
};
</script>
